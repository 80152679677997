<template>
<div class="inspect-index">
  <el-page-header @back="goBack" content="问卷调查"></el-page-header>
  <div class="content">
    <div class="school">
      <div class="item" v-for="(item,index) of radioList" :key="index">
        <div class="group-title">{{item.name}}</div>
        <div class="a-wrap">
          <el-radio class="radio" v-model="item.radio" label="1"><span>A 是</span></el-radio>
          <el-radio class="radio" v-model="item.radio" label="2"><span>B 否</span></el-radio>
        </div>
      </div>
      <div class="input">
        <div class="group-title">问题4：您对评估工具中的指标要求和评估软件的完善，有什么改进建议？</div>
        <div class="a-wrap">
          <el-input type="textarea" class="text" autosize placeholder="请输入内容" v-model="value1">
          </el-input>
        </div>
      </div>
      <div class="input">
        <div class="group-title">问题5：目前，托育机构在办托过程中，您认为还有哪些困难或问题需要解决？</div>
        <div class="a-wrap">
          <el-input type="textarea" class="text" autosize placeholder="请输入内容" v-model="value2">
          </el-input>
        </div>
      </div>
      <el-button :disabled="disabled" class="save-question" @click="save" type="primary">
        提交
      </el-button>
    </div>
  </div>
</div>
</template>

<script>
import obs from "@/utils/obs.js";
export default {
  name: "inspect-index",
  data() {
    return {
      type: '',
      radioList: [{
        id: 1,
        name: '问题1：总体上，您觉得这个评估软件是否使用方便？',
        radio: ''
      }, {
        id: 2,
        name: '问题2：总体上，您觉得评估工具中指标要求的表述是否清楚、便于理解？',
        radio: ''
      }, {
        id: 3,
        name: '问题3：总体上，您觉得评估工具中的指标要求是否能够有效地评估托育机构的实际？',
        radio: ''
      }],
      value1: '',
      value2: '',
      inspect_id: ''
    };
  },
  computed: {
    disabled() {
      return !(this.value1 && this.value2 && this.radioList[0].radio && this.radioList[1].radio && this.radioList[2].radio)
    }
  },
  mounted() {
    this.type = localStorage.getItem('role') != 4 ? 'admin' : 'school';
    this.inspect_id = this.$route.query.inspect_id;
    if (this.type == 'school') {
      this.radioList[2].name = '问题3：总体上，您觉得评估工具中的指标要求对于提高托育质量是否有帮助？'
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      let json = [];
      for (const iterator of this.radioList) {
        json.push({
          questionnaire_id: iterator.id,
          option: iterator.radio
        })
      }
      json.push({
        questionnaire_id: 4,
        option: this.value1
      })
      json.push({
        questionnaire_id: 5,
        option: this.value2
      })
      let result = json.filter(e => {
        return e.option != ''
      })
      if (result.length < 5) {
        this.$message('请填写所有问卷后提交')
        return;
      }
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/questionnaire_save",
        data: {
          inspect_id: this.inspect_id,
          result_json: JSON.stringify(json)
        }
      });
      if (res.code == 0) {
        this.$message.success('保存成功')
        this.goBack()
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inspect-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .school {
    padding-top: 20px;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .radio {
      display: flex;
    }

    .a-wrap {
      margin-top: 10px;
      display: flex;
      margin: 10px 0;
      align-items: center;
      height: 30px;
    }

    .text {
      width: 50%;
    }

    .item {
      border-bottom: 1px solid #e4e7ed;
    }

    .group-title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      padding: 10px 0 10px;

      span {
        color: #666;
        font-size: 12px;
      }
    }

    .save-question {
      width: 260px;
      float: left;
      margin: 50px auto;
    }
  }

}
</style>

<style lang="scss">
.inspect-index {
  .el-tabs__item {
    font-size: 16px;
    margin-top: 20px;
    font-weight: normal;
  }

  .el-tabs__item.is-active {
    font-weight: bold;
  }

  .admin-score {
    .el-input__inner {
      // border-color: #E9AE56 !important;
      // color: #E9AE56 !important;
      font-size: 13px !important;
    }

    .el-textarea {
      width: 400px;
    }

    .el-textarea__inner {
      // border-color: #E9AE56 !important;
      // color: #E9AE56 !important;
      font-size: 14px !important;

    }
  }

  .el-checkbox__label,
  .el-radio__label {
    font-size: 14px;
    color: #555;
    white-space: pre-line;
    line-height: 1.5;
  }

  .el-checkbox__inner,
  .el-radio__inner {
    margin-top: 3px;
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }

  .el-collapse-item__header {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .el-collapse-item__arrow {
    display: none;
  }

  .max-length {
    .el-upload.el-upload--picture-card {
      display: none;
    }
  }

  .filelist-group .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 1 100%;
    height: 30px;
    width: 345px;
    background: #ffffff;
    padding: 20px 0;
  }

  .filelist-group .el-upload--picture-card {
    height: 50px;
    clear: both;
  }

  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    display: flex;
    float: left;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .el-collapse {
    width: 100%;
    overflow: hidden;
  }

  .el-select .el-input .el-select__caret {
    display: none;
  }

  .el-input--mini .el-input__inner {
    padding: 0 4px;
    // color: #0083f5;
    // border-color: #0083f5;
    text-align: center;
    font-size: 12px;
    height: 25px;
  }

  .el-select-dropdown__item {
    text-align: center;
  }

  .el-tabs__nav-next,
  .el-tabs__nav-prev {
    padding-top: 20px;
    font-size: 16px;
  }
}

.customConfirm {
  .el-message-box__message p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .el-message-box__title {
    font-size: 22px;
  }
}

.confirmButtonClass {
  width: 120px;
  margin: 0 20px;
}

.cancelButtonClass {
  width: 120px;
  margin: 0 20px;
}
</style>
